

  export default {
    name: 'Flag',
    functional: true,
    props: {
      classString: {
        type: String,
        default: ''
      },
      countryShortcode: {
        type: String,
        default: 'en'
      }
    }
  }
