
  export default {
    name: 'NavbarDrawerList',
    functional: true,
    components: {},
    props: {
      items: {
        type: [Array, Object],
        default: () => []
      }
    }
  }
