
  import UserLoyaltyLevel from '@/database/models/UserLoyaltyLevel'

  export default {
    name: 'ProgressBar',
    functional: true,
    components: {},
    props: {
      value: {
        type: Number
      },
      max: {
        type: Number
      },
      color: {
        type: String,
        validator: val => {
          return UserLoyaltyLevel.includes(val)
        }
      },
      simple: {
        type: Boolean,
        default: false
      }
    }
  }
