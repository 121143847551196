import { mapGetters } from 'vuex'
import { set } from 'vue'
import NavigationPage from '~/database/models/NavigationPage'

export default {
  computed: {
    ...mapGetters({
      leagues: 'gamingClub/leagues'
    }),
    leagueAllOption() {
      return false
    },
    navigationTreeId() {
      return 'navigationTree'
    }
  },
  watch: {
    leagues: {
      handler() {
        this.remapNavigation()
      }
    }
  },
  methods: {
    remapNavigation() {
      const leagues = this.leagues
      const tree = [
        new NavigationPage({ full_url: 'gamingClubTournaments', is_route_name: true, localized_name: 'page_gaming_club_tournaments_allocation_title', name: 'page_gaming_club_tournaments_allocation_title', name_cz: 'page_gaming_club_tournaments_allocation_title', name_de: 'page_gaming_club_tournaments_allocation_title' }),
      ]

      for (const league of leagues) {
        tree.push(
          new NavigationPage({
            full_url: 'gamingClubLeague',
            params: {leagueId: String(league.id)},
            is_route_name: true,
            localized_name: league.nameLoc.en,
            name: league.nameLoc.en,
            name_cz: league.nameLoc.cs,
            name_de: league.nameLoc.en,
            children: [
              ...(this.leagueAllOption ? [
                {
                  full_url: 'gamingClubLeague',
                  params: {leagueId: String(league.id)},
                  is_route_name: true,
                  localized_name: league.nameLoc.en,
                  name: 'gc_season_current',
                  name_cz: 'gc_season_current',
                  name_de: 'gc_season_current',
                }
              ] : []),
              new NavigationPage({
                full_url: 'gamingClubLeagueStatistics',
                params: {leagueId: String(league.id)},
                is_route_name: true,
                localized_name: 'page_gaming_club_league_statistics_title',
                name: 'page_gaming_club_league_statistics_title',
                name_cz: 'page_gaming_club_league_statistics_title',
                name_de: 'page_gaming_club_league_statistics_title'
              }),

              new NavigationPage({
                full_url: 'gamingClubLeagueHistory',
                params: {leagueId: String(league.id)},
                is_route_name: true,
                localized_name: 'page_gaming_club_league_history_title',
                name: 'page_gaming_club_league_history_title',
                name_cz: 'page_gaming_club_league_history_title',
                name_de: 'page_gaming_club_league_history_title'
              }),
              new NavigationPage({
                full_url: 'gamingClubLeagueRules',
                params: {leagueId: String(league.id)},
                is_route_name: true,
                localized_name: 'page_gaming_club_league_rules_title',
                name: 'page_gaming_club_league_rules_title',
                name_cz: 'page_gaming_club_league_rules_title',
                name_de: 'page_gaming_club_league_rules_title'
              })
            ]
          })
        )
      }

      tree.push(
        new NavigationPage({ full_url: 'gamingClubHowToPage', is_route_name: true, localized_name: 'gc_banner_how_it_works', name: 'gc_banner_how_it_works', name_cz: 'gc_banner_how_it_works', name_de: 'gc_banner_how_it_works' }),
      )

      set(this, this.navigationTreeId, tree)
    }
  }
}
