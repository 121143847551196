
  import { mapActions, mapGetters } from 'vuex'
  // eslint-disable-next-line camelcase
  import NavbarSettings_Row from '@/components/layout/navbarComponent/Settings/NavbarSettings_Row'
  import Row from '@/components/layout/Row'
  import Flag from '@/components/general/elements/Flag'

  import { countryMap, langMap } from '@/services/Helpers'

  import SelectInput from '@/components/form/inputs/SelectInput'
  import { FormField, availableShippingCountryOptions } from '@/structures/FormField'
  import { executeGAEvent } from '~/services/AnalyticsService'

  export default {
    name: 'NavbarSettings',
    components: { SelectInput, Flag, Row, NavbarSettings_Row },
    data () {
      return {
        countries: ['cz', 'en'],
        countryField: new FormField({
          name: 'delivery_country',
          options: availableShippingCountryOptions(this.language)
        }),
        currencies: {
          CZK: 'currency_czk',
          EUR: 'currency_eur'
        }
      }
    },
    computed: {
      ...mapGetters({
        isAuthenticated: 'authentication/isAuthenticated',
        language: 'general/getLanguage',
        country: 'general/getCountry',
        currency: 'general/getCurrency'
      }),
      countryForActiveLanguage (vm) {
        if (langMap.has(vm.language)) {
          return langMap.get(vm.language)
        }

        return vm.language
      }
    },
    methods: {
      ...mapActions({
        updateHello: 'general/updateHello'
      }),

      setCurrency (currency) {
        executeGAEvent('settings', 'currency change', `${this.currency} -> ${currency}`)

        this.updatePreferences({ currency })
      },
      setLanguageForCountry (country) {
        const language = countryMap.get(country)
        executeGAEvent('settings', 'language change', `${this.language} -> ${language}`)

        this.updatePreferences({ language })
      },
      setCountry (country) {
        this.updatePreferences({ country })
      },

      async updatePreferences (data) {
        this.isLoading = true
        await this.$nextTick()

        try {
          await this.updateHello(data)
        } catch (e) {
          this.$log.error(e)
        } finally {
          this.isLoading = false
        }
      }
    },
    watch: {
      language: {
        immediate: true,
        handler (newValue) {
          this.countryField.options = availableShippingCountryOptions(newValue)
        }
      }
    }
  }
