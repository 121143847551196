
  export default {
    name: 'NavbarSettingsRow',
    props: {
      title: String,
      disabled: Boolean
    },
    data () {
      return {}
    },
    computed: {},
    methods: {

    }
  }
