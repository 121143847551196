
  import NavigationPage from '@/database/models/NavigationPage'

  export default {
    name: 'NavigationWrapper',
    inject: ['getLoc'],
    functional: true,
    props: {
      page: {
        type: NavigationPage
      },
      forced: Boolean
    },

    getTitle(page, getLoc) {
      return getLoc(page.titleLoc)
    }
  }
