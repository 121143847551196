
  import each from 'lodash/each'
  import findIndex from 'lodash/findIndex'
  import isString from 'lodash/isString'
  import { mapGetters } from 'vuex'

  import find from 'lodash/find'
  import ProgressBar from '@/components/general/elements/ProgressBar'
  // eslint-disable-next-line camelcase
  import NavbarDrawer_List from '@/components/layout/navbarComponent/NavbarDrawer_List'
  import NavigationWrapper from '@/components/general/elements/NavigationWrapper'
  // eslint-disable-next-line camelcase
  import NavbarDrawer_Item from '@/components/layout/navbarComponent/NavbarDrawer_Item'

  import NavigationPage from '@/database/models/NavigationPage'

  import Row from '@/components/layout/Row'
  import NavbarSettings from '@/components/layout/navbarComponent/Settings/NavbarSettings'
  import ComingSoon from '@/components/general/elements/ComingSoon'
  import GCNavigationMixin from '~/configurations/mixins/GCNavigationMixin'
  import { pagesForPathFragments, splitPath } from '~/services/NavigationService'
  import { localizeRouteName } from '~/services/RouterService'

  const GCPlayerAccountSide = () => import('~/components/layout/sideNavigation/subviews/gaming-club/GCPlayerAccountSide')

  export default {
    name: 'NavbarDrawer',
    components: {
      ComingSoon,
      NavbarSettings,
      Row,
      NavbarDrawer_Item,
      NavigationWrapper,
      NavbarDrawer_List,
      ProgressBar,
      GCPlayerAccountSide
    },
    mixins: [GCNavigationMixin],
    inject: ['getLoc'],
    props: {},
    data () {
      return {
        shouldShowSubMenu: false,
        shouldShowInnerSubMenu: false,
        showSettings: false,
        showComingSoon: false,
        subMenuTitle: null,
        innerSubMenuTitle: null,
        isUserArea: false,
        isGamingClubGroup: false,
        subCategories: {},
        innerSubCategories: {},
        userAreaPages: [],
        gamingClubPages: []
      }
    },
    computed: {
      ...mapGetters({
        language: 'general/getLanguage',
        isAuthenticated: 'authentication/isAuthenticated',
        userPicture: 'authentication/userPicture',
        username: 'authentication/userFullName',
        userCredit: 'authentication/userTotalCredit',
        userProgress: 'authentication/userLoyaltyProgressToNextLevel',
        profileRank: 'authentication/userLoyaltyLevel',
        leagues: 'gamingClub/leagues',
        getMenu: 'menu/getMenu',
      }),
      menuItems(vm) {
        const items = [...vm.$store.state.menu.menu]
          .filter(item => item.special_positioning === 'NAV_MENU_TOP')
          .map(item => new NavigationPage(item))

        const dontMissIndex = findIndex(items, item => item.url_fragment === 'dont-miss')
        if (dontMissIndex > -1) {
          items[dontMissIndex] = new NavigationPage(this.getMenu('new'))
          items.splice(dontMissIndex + 1, 0, new NavigationPage(this.getMenu('presale')))
        }

        const singlesItem = find(items, item => item.url_fragment === 'single-cards')
        if(singlesItem) {
          singlesItem.icon = 'SINGLES'
        }

        return items.map(item => {
          item.children = item.children.map(item => new NavigationPage(item))
          return item
        })
      },
      hasUserPicture(vm) {
        return isString(vm.userPicture)
      },

      transition (vm) {
        return (vm.shouldShowSubMenu || vm.shouldShowInnerSubMenu) ? 'leave-slide-left' : 'leave-slide-right'
      },
      navigationTreeId() {
        return 'gamingClubPages'
      },
      leagueAllOption() {
        return true
      },
      gamingClubPage(vm) {
        return new NavigationPage({
          name: 'page_howto-gaming-club',
          name_cz: 'page_howto-gaming-club',
          name_de: 'page_howto-gaming-club',
          children: vm.gamingClubPages,
          full_url: 'gamingClub',
          icon: 'GC'
        })
      }
    },
    watch: {
      isStage: {
        immediate: true,
        handler (isStage) {
          this.userAreaPages = NavigationPage.getUserNavigationMobile(isStage ? [] : ['userTournaments'])
        }
      },
      $route: {
        immediate: true,
        handler ($route) {
          this.remapNavigation()
          const fragments = splitPath($route.path)
          const pages = pagesForPathFragments(fragments, this.$store.state.menu.menu)

          if (!this.isGamingClubGroup) {
            this.isUserArea = $route.matched.some(route => route.meta.sidebar === 'user')
            this.shouldShowSubMenu = this.isUserArea

            if (this.isUserArea) {
              this.showUserAreaMenu()
            } else {
              this.setNavigationHierarchy(pages)
            }
          }
        }
      }
    },
    methods: {
      /**
       * @param {NavigationPage[]} pages
       */
      setNavigationHierarchy(pages) {
        const oThis = this

        this.hideSubMenu()
        this.hideInnerSubMenu()

        each(pages, (page, index) => {
          const forceRouterPush = false
          const innerSub = index === 1
          oThis.menuItemSelected(page, forceRouterPush, innerSub)
        })
      },
      prependAllOption (children, parent, parentFull, isMtg = false) {
        const isSinglesParent = parentFull.startsWith('/single-cards')
        let name = 'search_category-all-products'
        const parentMapping = new Map([
          ['mtg', 'search_category-all-products'],
          ['card_games', 'search_category-all-card-games'],
          ['tabletop_games', 'search_category-all-tabletop-games'],
          ['miniatures', 'search_category-all-miniatures']
        ])

        if (parentMapping.has(parent)) {
          name = parentMapping.get(parent)
        }

        const allItem = new NavigationPage({
          id: '-1',
          name,
          name_cz: name,
          name_de: name,
          url: parentFull,
          full_url: parentFull,
          full_url_cz: parentFull,
          icon: null
        })
        const items = [...children]

        if (isMtg) {
          items.splice(2, 0, allItem)
        } else if (!isSinglesParent) {
          items.splice(0, 0, allItem)
        }

        return items
      },
      menuItemSelected (page, forceRouterPush = false, innerSub = false) {
        this.isGamingClubGroup = page.isGamingClubGroup

        if (page.hasChildren && !forceRouterPush) {
          if (innerSub) {
            let items = this.prependAllOption(page.childrenPages, page.urlFragment, page.url, false)

            if(page.isGamingClubGroup) items = page.childrenPages

            this.innerSubCategories = items
            this.showInnerSubMenu(this.getLoc(page.titleLoc))
          } else {
            let items = this.prependAllOption(page.childrenPages, page.urlFragment, page.url, page.isMtgGroup)

            if(page.isGamingClubGroup) items = page.childrenPages

            this.subCategories = items
            this.showSubMenu(this.getLoc(page.titleLoc))
          }
        } else {
          this.closeRequested()
          let routerObject = this.getLoc(page.fullUrlLoc)

          if (page.isRouteName) {
            routerObject = { name: localizeRouteName(page.url, this.language), params: page.params }
          }

          if(forceRouterPush) {
            this.$router.push(routerObject)
          }
        }
      },
      showSubMenu (title) {
        this.shouldShowSubMenu = true
        this.subMenuTitle = title
      },
      hideSubMenu () {
        this.showSettings = false
        this.showComingSoon = false
        this.shouldShowSubMenu = false
        this.subMenuTitle = null
      },
      showInnerSubMenu (title) {
        this.shouldShowInnerSubMenu = true
        this.innerSubMenuTitle = title
      },
      hideInnerSubMenu () {
        this.showSettings = false
        this.showComingSoon = false
        this.shouldShowInnerSubMenu = false
        this.innerSubMenuTitle = null
      },
      closeRequested () {
        // this.shouldShowSubMenu = false
        // this.shouldShowInnerSubMenu = false
        this.showSettings = false
        this.$emit('closeRequested')
      },
      login () {
        this.closeRequested()
        this.$modal.showLogin()
      },

      showUserAreaMenu () {
        this.shouldShowSubMenu = true
        this.subCategories = this.userAreaPages
        this.subMenuTitle = 'page_user'
      },
      showSettingsArea () {
        this.showSettings = true
        this.shouldShowSubMenu = true
        this.subMenuTitle = 'page_user_settings'
      },
      showComingSoonArea (title) {
        this.showComingSoon = true
        this.shouldShowSubMenu = true
        this.subMenuTitle = title
      }
    }
  }
