
  export default {
    name: 'NavbarDrawerItem',
    functional: true,
    components: {},
    inject: ['getLoc'],
    props: {
      item: {
        type: Object,
        required: true
      },
      hasArrow: {
        type: Boolean,
        default: false
      },
      noIconBg: Boolean
    }
  }
