import { render, staticRenderFns } from "./Flag.vue?vue&type=template&id=551ff722&functional=true"
import script from "./Flag.vue?vue&type=script&lang=js"
export * from "./Flag.vue?vue&type=script&lang=js"
import style0 from "./Flag.vue?vue&type=style&index=0&id=551ff722&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports